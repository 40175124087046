import React, { useState, useEffect } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
import axios from 'axios';
import idl from '../idl.json';
import './SubmitReport.css';

const programID = new web3.PublicKey('9AKJ7pcX6kr1ruApf8hQs62TwpESWFheGzrrhWskAKzr');

const INCIDENT_TYPES = {
  'Data Protection': 0,
  'Health and Safety': 1,
  'Other': 2
};

const INCIDENT_TYPES_REVERSE = {
  0: 'Data Protection',
  1: 'Health and Safety',
  2: 'Other'
};

const API_BASE_URL = 'https://sirs.irish:5000';

const SubmitReport = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [incidentType, setIncidentType] = useState('');
  const [severity, setSeverity] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [userIdOrName, setUserIdOrName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [solution, setSolution] = useState('');
  const [resolverId, setResolverId] = useState('');
  const [submittedReports, setSubmittedReports] = useState([]);
  const [showDetails, setShowDetails] = useState({});
  const [activeCategory, setActiveCategory] = useState({ open: false, closed: false });
  const [currentPage, setCurrentPage] = useState({ open: 1, closed: 1 });

  const reportsPerPage = 10;

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/incidents`);
        const reports = response.data.map(report => ({
          ...report,
          _id: report._id,  // Ensure _id is included
          submissionTimestamp: report.submissionTimestamp,
          closedTimestamp: report.closedTimestamp
        }));
        setSubmittedReports(reports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();
  }, []);

  const getProvider = () => {
    if (!publicKey) {
      throw new Error('Wallet not connected!');
    }
    const provider = new AnchorProvider(connection, window.solana, {
      preflightCommitment: 'processed'
    });
    return provider;
  };

  const generateUniqueIdBuffer = (uniqueId) => {
    const buffer = new ArrayBuffer(8);
    const view = new DataView(buffer);
    view.setUint32(0, uniqueId, true);
    view.setUint32(4, Math.floor(uniqueId / 0x100000000), true);
    return Buffer.from(buffer);
  };

  const fetchReportDetails = async (reportPDA) => {
    const provider = getProvider();
    const program = new Program(idl, programID, provider);
    const reportAccount = await program.account.incidentReport.fetch(reportPDA);
    return reportAccount;
  };

  const getCurrentSolanaTimestamp = async () => {
    const clockAccountInfo = await connection.getAccountInfo(web3.SYSVAR_CLOCK_PUBKEY);
    const clockData = clockAccountInfo.data.slice(32, 40);
    const unixTimestamp = new BN(clockData, 10, 'le').toNumber();
    return unixTimestamp;
  };

  const fetchUpdatedReports = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/incidents`);
      const reports = response.data.map(report => ({
        ...report,
        _id: report._id,
        submissionTimestamp: report.submissionTimestamp,
        closedTimestamp: report.closedTimestamp
      }));
      setSubmittedReports(reports);
    } catch (error) {
      console.error('Error fetching updated reports:', error);
    }
  };

  const submitReport = async (e) => {
    e.preventDefault();
    if (!publicKey) {
      console.error('Wallet not connected!');
      return;
    }
    if (incidentType === '' || severity === '') {
      alert('Please select incident type and severity.');
      return;
    }
    const provider = getProvider();
    const program = new Program(idl, programID, provider);

    try {
      const uniqueId = Date.now();
      const uniqueIdBuffer = generateUniqueIdBuffer(uniqueId);
      const [reportPDA, bump] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from("report"),
          publicKey.toBuffer(),
          uniqueIdBuffer
        ],
        program.programId
      );

      const solanaTimestamp = await getCurrentSolanaTimestamp();

      const tx = await program.methods.submitReport(
        new BN(uniqueId),
        INCIDENT_TYPES[incidentType],  // Ensure proper mapping
        severity,
        Buffer.from(title),
        Buffer.from(description),
        Buffer.from(userIdOrName),
        Buffer.from(userRole),
        bump
      )
      .accounts({
        report: reportPDA,
        submitter: publicKey,
        systemProgram: web3.SystemProgram.programId,
      })
      .rpc();

      const reportDetails = await fetchReportDetails(reportPDA);

      const newReport = {
        signature: tx,
        reportPDA: reportPDA.toBase58(),
        uniqueId,
        title,
        description,
        userIdOrName,
        userRole,
        severity,
        incidentType: INCIDENT_TYPES[incidentType],  // Ensure proper mapping
        submissionTimestamp: solanaTimestamp, // Use the Solana timestamp
        status: 'Open'
      };

      console.log('New Report:', newReport); // Log new report data

      await axios.post(`${API_BASE_URL}/incidents`, newReport);

      await fetchUpdatedReports();

      setIncidentType('');
      setSeverity('');
      setTitle('');
      setDescription('');
      setUserIdOrName('');
      setUserRole('');
    } catch (error) {
      console.error("Error submitting report:", error);
      if (error.response && error.response.data) {
        console.error("Backend error message:", error.response.data);
      }
    }
  };

  const resolveReport = async (report) => {
    if (solution === '' || resolverId === '') {
      alert('Please provide a solution and resolver ID.');
      return;
    }
    const provider = getProvider();
    const program = new Program(idl, programID, provider);

    try {
      const addSolutionTx = await program.methods.addSolution(solution)
      .accounts({
        report: report.reportPDA,
        submitter: publicKey,
      })
      .rpc();

      const closeTx = await program.methods.closeReport()
      .accounts({
        report: report.reportPDA,
        submitter: publicKey,
      })
      .rpc();

      console.log('Add Solution Transaction ID:', addSolutionTx);
      console.log('Close Report Transaction ID:', closeTx);

      const solanaTimestamp = await getCurrentSolanaTimestamp();

      const updatedReport = {
        ...report,
        status: 'Closed',
        resolverId,
        solution,
        closedTimestamp: solanaTimestamp // Use the Solana timestamp
      };

      console.log('Updating report with ID:', report._id);  // Log the report ID

      await axios.put(`${API_BASE_URL}/incidents/${report._id}`, updatedReport);

      await fetchUpdatedReports();

      setSolution('');
      setResolverId('');

      alert(`Report resolved successfully!\nAdd Solution TX: ${addSolutionTx}\nClose Report TX: ${closeTx}`);
    } catch (error) {
      console.error("Error resolving report:", error);
      if (error.response && error.response.data) {
        console.error("Backend error message:", error.response.data);
      }
    }
  };

  const toggleDetails = (index) => {
    setShowDetails(prevState => ({ ...prevState, [index]: !prevState[index] }));
  };

  const toggleCategory = (category) => {
    setActiveCategory(prevState => ({ ...prevState, [category]: !prevState[category] }));
  };

  const openReports = submittedReports
    .filter(report => report.status === 'Open')
    .sort((a, b) => a.submissionTimestamp - b.submissionTimestamp)
    .slice((currentPage.open - 1) * reportsPerPage, currentPage.open * reportsPerPage);

  const closedReports = submittedReports
    .filter(report => report.status === 'Closed')
    .sort((a, b) => a.submissionTimestamp - b.submissionTimestamp)
    .slice((currentPage.closed - 1) * reportsPerPage, currentPage.closed * reportsPerPage);

  const openReportsCount = submittedReports.filter(report => report.status === 'Open').length;
  const closedReportsCount = submittedReports.filter(report => report.status === 'Closed').length;

  return (
    <div className="submit-report">
      <h2>Submit a New Report</h2>
      {publicKey ? (
        <form onSubmit={submitReport}>
          <select value={incidentType} onChange={(e) => setIncidentType(e.target.value)}>
            <option value="" disabled>Please select incident type</option>
            <option value="Data Protection">Data Protection</option>
            <option value="Health and Safety">Health and Safety</option>
            <option value="Other">Other</option>
          </select>
          <select value={severity} onChange={(e) => setSeverity(Number(e.target.value))}>
            <option value="" disabled>Please select severity of the incident</option>
            {[1, 2, 3, 4, 5].map(level => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            type="text"
            placeholder="User ID or Name"
            value={userIdOrName}
            onChange={(e) => setUserIdOrName(e.target.value)}
          />
          <input
            type="text"
            placeholder="User Role"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
          />
          <button type="submit">Submit Report</button>
        </form>
      ) : (
        <p>Please connect your wallet to submit a report.</p>
      )}

      <div className="report-counts">
        <p>Open Reports: {openReportsCount}</p>
        <p>Closed Reports: {closedReportsCount}</p>
      </div>

      <div className={`submitted-reports ${activeCategory.open ? 'active' : ''}`}>
        <h3 onClick={() => toggleCategory('open')}>Open Reports</h3>
        <ul>
          {openReports.map((report, index) => (
            <React.Fragment key={index}>
              <li className="report-item">
                <div onClick={() => toggleDetails(index)}>
                  <p><strong>Incident ID:</strong> {report.uniqueId}</p>
                  <p><strong>Submission Timestamp:</strong> {new Date(report.submissionTimestamp * 1000).toLocaleString()}</p>
                </div>
                {showDetails[index] && (
                  <div className="report-details">
                    <p><strong>Title:</strong> {report.title}</p>
                    <p><strong>Description:</strong> {report.description}</p>
                    <p><strong>User ID or Name:</strong> {report.userIdOrName}</p>
                    <p><strong>User Role:</strong> {report.userRole}</p>
                    <p><strong>Severity:</strong> {report.severity}</p>
                    <p><strong>Incident Type:</strong> {INCIDENT_TYPES_REVERSE[report.incidentType]}</p>
                    <textarea
                      placeholder="Solution"
                      value={solution}
                      onChange={(e) => setSolution(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Resolver ID"
                      value={resolverId}
                      onChange={(e) => setResolverId(e.target.value)}
                    />
                    <button onClick={() => resolveReport(report)}>Resolve</button>
                  </div>
                )}
                <p>
                  <strong>Transaction ID:</strong> 
                  <a 
                    href={`https://explorer.solana.com/tx/${report.signature}?cluster=devnet`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {report.signature}
                  </a>
                </p>
              </li>
              <div className="report-divider"></div>
            </React.Fragment>
          ))}
        </ul>
        <div className="pagination">
          {Array.from({ length: Math.ceil(openReportsCount / reportsPerPage) }, (_, i) => (
            <button key={i + 1} onClick={() => setCurrentPage(prevState => ({ ...prevState, open: i + 1 }))}>
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      <div className={`submitted-reports ${activeCategory.closed ? 'active' : ''}`}>
        <h3 onClick={() => toggleCategory('closed')}>Closed Reports</h3>
        <ul>
          {closedReports.map((report, index) => (
            <React.Fragment key={index}>
              <li className="report-item">
                <div onClick={() => toggleDetails(index)}>
                  <p><strong>Incident ID:</strong> {report.uniqueId}</p>
                  <p><strong>Submission Timestamp:</strong> {new Date(report.submissionTimestamp * 1000).toLocaleString()}</p>
                </div>
                {showDetails[index] && (
                  <div className="report-details">
                    <p><strong>Title:</strong> {report.title}</p>
                    <p><strong>Description:</strong> {report.description}</p>
                    <p><strong>User ID or Name:</strong> {report.userIdOrName}</p>
                    <p><strong>User Role:</strong> {report.userRole}</p>
                    <p><strong>Severity:</strong> {report.severity}</p>
                    <p><strong>Incident Type:</strong> {INCIDENT_TYPES_REVERSE[report.incidentType]}</p>
                    <p><strong>Solution:</strong> {report.solution}</p>
                    <p><strong>Resolver ID:</strong> {report.resolverId}</p>
                    <p><strong>Closed Timestamp:</strong> {report.closedTimestamp ? new Date(report.closedTimestamp * 1000).toLocaleString() : 'N/A'}</p>
                  </div>
                )}
                <p>
                  <strong>Transaction ID:</strong> 
                  <a 
                    href={`https://explorer.solana.com/tx/${report.signature}?cluster=devnet`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {report.signature}
                  </a>
                </p>
              </li>
              <div className="report-divider-closed"></div>
            </React.Fragment>
          ))}
        </ul>
        <div className="pagination">
          {Array.from({ length: Math.ceil(closedReportsCount / reportsPerPage) }, (_, i) => (
            <button key={i + 1} onClick={() => setCurrentPage(prevState => ({ ...prevState, closed: i + 1 }))}>
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubmitReport;

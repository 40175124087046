import React from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import SubmitReport from './components/SubmitReport';

require('@solana/wallet-adapter-react-ui/styles.css');

const App = () => {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = clusterApiUrl(network);

  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
  ];

  const disclaimer = `
    <h1>SIRS Incident Reporting System<br/>PROTOTYPE VERSION</h1>
    <p>This system is a prototype developed for DCU Practicum - CA6001I<br/>
    Student ID: 22268291</p>
    <p>
    WARNING:
    <ul>
        <li>Do not enter any real or identifiable data</li>
        <li>For use with synthetic data only</li>
        <li>Not for operational or production use</li>
    </ul>
    This prototype is intended solely for academic purposes and evaluation.
    </p>
  `;

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="App">
            <header className="App-header">
              <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
              <WalletMultiButton />
            </header>
            <main>
              <SubmitReport />
            </main>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;